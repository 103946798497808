import { IUser } from 'src/types/user';

export interface ITokenInfo {
  accessToken: string;
  refreshToken: string;
}

interface IGlobalVariable {
  tokenInfo?: ITokenInfo | null;
  user?: IUser | undefined;
  activeRouteKey?: string;
}

export const GlobalVariable: IGlobalVariable = {};
