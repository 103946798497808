// @ts-nocheck
import IcLogoHeader from 'src/assets/svg/layout/ic_logo_header.svg';
import IcLogoNotifyHeader from 'src/assets/svg/layout/ic_logo_notify_header.svg';
import { ReactComponent as IcCall } from './common/ic_call.svg';
import IcClock from './common/ic_clock.svg';
import IcCloudDownload from './common/ic_cloud_download.svg';
import { ReactComponent as IcCloudUpload } from './common/ic_cloud_upload.svg';
import IcDelete from './common/ic_delete.svg';
import IcDownload from './common/ic_download.svg';
import IcEdit from './common/ic_edit.svg';
import IcEndCall from './common/ic_end_call.svg';
import IcMore from './common/ic_more.svg';
import IcPassword from './common/ic_password.svg';
import IcSearch from './common/ic_search.svg';
import IcTelephone from './common/ic_telephone.svg';

import { ReactComponent as IcMenuDashboard } from './layout/ic_menu_dashboard.svg';
import { ReactComponent as IcMenuOrder } from './layout/ic_menu_order.svg';
import { ReactComponent as IcMenuQLKH } from './layout/ic_menu_qlkh.svg';
import { ReactComponent as IcMenuSetting } from './layout/ic_menu_setting.svg';

const SgvIcon = {
  IcLogoHeader,
  IcLogoNotifyHeader,
  IcMenuQLKH,
  IcMenuDashboard,
  IcMenuOrder,
  IcMenuSetting,
  IcCloudDownload,
  IcCloudUpload,
  IcSearch,
  IcEdit,
  IcCall,
  IcTelephone,
  IcEndCall,
  IcClock,
  IcMore,
  IcPassword,
  IcDelete,
  IcDownload,
};

export default SgvIcon;
