import { notification } from 'antd';
import React, { useCallback } from 'react';
import { ResponseCode } from 'src/constants/http-status';
import { logOut } from 'src/store/auth/auth.actions';
import { useAppDispatch } from 'src/store/configureStore';
import { BaseProps } from 'src/utils/baseProps';
import { ErrorResponse } from 'src/utils/exception';
import { ErrorContext } from './useError';

const ErrorProvider = ({ children }: BaseProps) => {
  const dispatch = useAppDispatch();

  const addError = async (err: Error & ErrorResponse) => {
    if (err.status === ResponseCode.FORBIDDEN || err.statusCode === ResponseCode.UNAUTHORIZED) {
      dispatch(logOut());
      return;
    }
    if (Array.isArray(err.message) && err.message.length > 0) {
      err.message.forEach((error: string) => {
        notification.error({
          message: error,
        });
      });
      return;
    }
    notification.error({ message: err.message });
  };

  const contextValue = {
    addError: useCallback((err: Error & ErrorResponse) => addError(err), []),
  };

  return <ErrorContext.Provider value={contextValue}>{children}</ErrorContext.Provider>;
};

export default React.memo(ErrorProvider);
