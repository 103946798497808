import { createSlice } from '@reduxjs/toolkit';
import { logInAction, logOut, registerAction } from './auth.actions';
import { AuthState } from './auth.state';

export const initialState: AuthState = Object.freeze({
  isAuthenticated: false,
  status: 'idle',
  user: {},
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /** Register action */
    builder
      .addCase(registerAction.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(registerAction.fulfilled, (state, { payload }) => {
        state.isAuthenticated = true;
        state.status = 'resolved';
        state.user = payload;
      })
      .addCase(registerAction.rejected, (state, { payload }) => {
        state.isAuthenticated = false;
        state.status = 'rejected';
        throw payload;
      });

    /** Login action */
    builder
      .addCase(logInAction.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(logInAction.fulfilled, (state, { payload }) => {
        state.isAuthenticated = true;
        state.status = 'resolved';
        state.user = payload;
      })
      .addCase(logInAction.rejected, (state, { payload }) => {
        state.isAuthenticated = false;
        state.status = 'rejected';
        state.user = {};
        throw payload;
      });

    /** logout action */
    builder
      .addCase(logOut.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(logOut.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.status = 'resolved';
        state.user = null;
      })
      .addCase(logOut.rejected, (state, { payload }: any) => {
        state.isAuthenticated = false;
        state.status = 'rejected';
        state.user = null;
      });
  },
});

export const {} = authSlice.actions;

export default authSlice.reducer;
