import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { GlobalVariable } from 'src/constants/global-variable';
import Page403 from 'src/screens/errors/Page403';
import { apiGetUserMe } from 'src/services/auth.service';
import { logOut } from 'src/store/auth/auth.actions';
import { useAppDispatch, useAppSelector } from 'src/store/configureStore';
import { BaseProps } from 'src/utils/baseProps';
import { ROUTE_CONFIG } from './helpers';

const PrivateRoute = (props: BaseProps) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const { isAuthenticated, user, status } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (status === 'idle' || status === 'pending') {
      navigation(ROUTE_CONFIG.DASHBOARD);
      dispatch(logOut());
    }
  }, [status]);

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = async () => {
    try {
      const user: any = await apiGetUserMe();
      GlobalVariable.user = user;
    } catch (error) {}
  };

  // const userHasRequiredRole = user && user.isAdmin ? true : false;
  const userHasRequiredRole = true; // TODO

  if (!isAuthenticated) {
    return <Navigate to={ROUTE_CONFIG.LOGIN} />;
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <Page403 />;
  }

  if (!user) {
    return null;
  }

  if (user && !GlobalVariable.user) {
    GlobalVariable.user = user;
  }

  return <>{props.children}</>;
};

export default React.memo(PrivateRoute);
