import { ConfigProvider } from 'antd';
import 'moment/locale/vi';
import React, { useEffect } from 'react';
import { getConfigProviderAntd } from 'src/i18n';
import { ELocale } from 'src/types/locale';
import { BaseProps } from 'src/utils/baseProps';

const LocaleProvider = (props: BaseProps) => {
  const currentAppLocale: ELocale = ELocale.VI;

  useEffect(() => {
    // Todo
  }, []);

  return <ConfigProvider locale={getConfigProviderAntd(currentAppLocale)}>{props.children}</ConfigProvider>;
};

export default LocaleProvider;
