import { Popover } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import SgvIcon from 'src/assets/svg';
import { ROUTE_CONFIG } from 'src/routers/helpers';
import { logOut } from 'src/store/auth/auth.actions';
import { useAppDispatch, useAppSelector } from 'src/store/configureStore';
import { IUser } from 'src/types/user';
import LayoutHeaderComponent from './header';
import './style.scss';

const LayoutElement = () => {
  const { t } = useTranslation();
  const user: IUser = useAppSelector((state) => state.auth?.user);
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const onLogout = () => {
    localStorage.removeItem('tokenInfo');
    navigation(ROUTE_CONFIG.LOGIN);
    dispatch(logOut());
  };

  return (
    <div>
      <div className="nav-header-blank" />
      <div className="nav-header">
        <Link to={ROUTE_CONFIG.DASHBOARD} style={{ margin: 'auto' }}>
          <img src={SgvIcon.IcLogoHeader} />
        </Link>
        <LayoutHeaderComponent />
        <img src={SgvIcon.IcLogoNotifyHeader} width={24} />

        {/* Todo */}
        <Popover
          content={
            <div>
              <p className="fw-6 txt-color-primary">{user?.branch_name}</p>
              <p className="nav-btn-info">{t('layout.info')}</p>
              <p className="nav-btn-info" onClick={onLogout}>
                {t('layout.logout')}
              </p>
            </div>
          }
          trigger="click"
          overlayStyle={{
            borderRadius: 10,
          }}
          placement="bottomLeft"
        >
          <img className="avatar-header" src="https://cdn-icons-png.flaticon.com/512/1053/1053244.png" />
        </Popover>
      </div>
      <div className="layout-content">
        <Outlet />
      </div>
      <div className="nav-bottom">{t('layout.txtBottom')}</div>
    </div>
  );
};

export default React.memo(LayoutElement);
