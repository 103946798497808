import endpoints from 'src/constants/endpoints';
import { GlobalVariable } from 'src/constants/global-variable';
import ApiRequest from 'src/services/http';
import { LoginData, RegisterData } from 'src/types/user';

export const registerService = async (params: RegisterData) => {
  try {
    const url = endpoints.auth.register;
    const data = await ApiRequest.post(url, params);
    return data;
  } catch (err) {
    throw err;
  }
};

export const logInService = async (params: LoginData) => {
  try {
    const url = endpoints.auth.login;
    const data: any = await ApiRequest.post(url, params);
    localStorage.setItem('tokenInfo', JSON.stringify(data));
    GlobalVariable.tokenInfo = data;
    return data;
  } catch (err) {
    throw err;
  }
};

export const logOutService = async () => {
  try {
    const url = endpoints.auth.logout;
    await ApiRequest.post(url);
  } catch (err) {
    throw err;
  }
};

export const apiGetUserMe = async () => {
  try {
    const data = await ApiRequest.get(endpoints.auth.me);
    return data || undefined;
  } catch (err) {
    throw err?.response?.data || err;
  }
};
