import React, { useContext } from 'react';
import { ErrorResponse } from 'src/utils/exception';

interface IErrorContext {
  addError: (err: Error & ErrorResponse) => void;
}

function useError() {
  const { addError } = useContext(ErrorContext);
  return { addError };
}

export const ErrorContext = React.createContext<IErrorContext>({
  addError: (err: Error & ErrorResponse) => ({}),
});

export default useError;
