export const ROUTE_CONFIG = {
  DASHBOARD: '/dashboard/order',
  DASHBOARD_ORDER: '/dashboard/order',
  DASHBOARD_SALARY: '/dashboard/salary',

  LOGIN: '/login',
  USER_LIST: '/user/list',

  ORDER_LIST: '/order/list',
  ORDER_ADD: '/order/add',
  ORDER_DETAIL: '/order/:id',
  ORDER: '/order',
  ORDER_EDIT: '/order/edit/',

  MATERIAL_LIST: '/material/list',
  CUSTOMER_LIST: '/customer/list',

  BRAND_LIST: '/branch/list',
  PROPERTIES_LIST: '/properties/list',
  SALARY_LIST: '/salary/list',
};
