import { createAsyncThunk } from '@reduxjs/toolkit';
import { logInService, logOutService, registerService } from 'src/services/auth.service';
import { LoginData, RegisterData } from 'src/types/user';

export const registerAction = createAsyncThunk('auth/register', async (registerData: RegisterData, thunkAPI) => {
  try {
    const data = await registerService(registerData);
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const logInAction = createAsyncThunk('auth/logIn', async (loginData: LoginData, thunkAPI) => {
  try {
    const data = await logInService(loginData);
    return data;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const logOut = createAsyncThunk('auth/logOut', async (_, thunkAPI) => {
  try {
    await logOutService();
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
