export default {
  auth: {
    register: '/api/auth/register',
    login: 'auth/login',
    me: 'users/me',
    logout: '/api/auth/logout',
    refreshToken: '/api/auth/refresh',
    profile: '/api/users/profile',
    updateFcmToken: '/api/users/updateFcmToken',
    getById: '/api/auth/getById',
  },
  user: {},
  customer: {
    listCustomer: '/customers',
  },
  marketing: {
    detail: '/api/mktPlanDetail/getDetail',
    manage: '/api/mktPlan',
    create: '/api/mktPlan/create',
    mktChannel: '/api/cMktChannel/findAll',
    chanel_mktById: 'api/cMktChannel/findOne',
    getMktDetail: '/api/mktPlan/getDataForEditing/',
    getAllChannelMkt: '/api/cMktChannel/findAll',
    getAllChannelDatacold: '/api/cMktDatacold/findAll',
    dataHold: '/api/cMktDatacold/findAll',
    updateMkt: '/api/mktPlan/updateById',
  },
  potentialCustomer: {
    managerTelesaleList: '/api/potentialCustomer/managerTelesale',
    telesaleList: '/api/potentialCustomer/telesale',
    list: '/api/potentialCustomer/find',
    fileTemplate: '/api/potentialCustomer/template',
    uploadExcel: '/api/potentialCustomer/upload/potentialCustomer',
    downloadExcelErr: '/api/potentialCustomer/download/failResults/',
    create_custom: 'api/potentialCustomer/create',
    saleCustomerLatestStatus: '/api/potentialCustomer/saleCustomerLatestStatus/',
    undividedWarehouse: 'api/potentialCustomer/getUndistributedStore',
    getCustomerById: '/api/potentialCustomer/detail',
    updateCustomerById: '/api/potentialCustomer',
    getPhonenumber: 'api/potentialCustomer/getPhonenumber/',
    receptionistCreatePotential: 'api/potentialCustomer/create/receptionist',
  },
  comboBox: {
    marketing: '/api/mktPlan/getMktPlanForCombobox',
    marketingDetails: 'api/mktPlan/getMktDetailsForCombobox',
    branch: '/api/branch/getForCombobox',
    cService: '/api/cService/getForCombobox',
  },
  getAllCareer: '/api/cCareer',
  telesalePotentialCustomer: {
    listTelesale: '/api/auth/telesales',
    manageAssign: 'api/telesalePotentialCustomer/assignByManaTeleSale',
    telesaleAssign: 'api/telesalePotentialCustomer/assignByTeleSale',
    accountRateConfig: 'api/accountRate/update',
    autoAssign: 'api/telesalePotentialCustomer/autoAssign',
  },
  telesaleHistory: {
    callLater: '/api/telesaleHistory/callLater',
    book: '/api/telesaleHistory/book',
    getOverloadBookingTime: '/api/telesaleHistory/getOverloadBookingTime',
    updateCommonStatus: '/api/telesaleHistory/updateCommonStatus',
    telesaleHistory: '/api/telesaleHistory/',
  },
  role: {
    findAll: '/api/role/findAll',
    findOneById: '/api/role/findOne/',
  },
};
