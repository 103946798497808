import { useState } from 'react';
import SpinnerLoader from 'src/containers/components/loader';
import { BaseProps } from 'src/utils/baseProps';
import { LoadingContext } from './userLoading';

export function LoadingProvider(props: BaseProps) {
  const [loading, setLoading] = useState(false);

  const contextValue = {
    loading: loading,
    showLoading: () => setLoading(true),
    hideLoading: () => setLoading(false),
  };

  return (
    <LoadingContext.Provider value={contextValue}>
      {loading && <SpinnerLoader />}
      {props.children}
    </LoadingContext.Provider>
  );
}
