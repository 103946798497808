import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import './styled.css';

const SpinnerLoader = () => {
  const antIcon = <LoadingOutlined className="loading3QuartersOutlinedStyled" spin={true} />;
  return (
    <div className="spinDiv">
      <Spin className="spinStyled" indicator={antIcon} />
    </div>
  );
};

export default React.memo(SpinnerLoader);
