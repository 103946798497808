import { Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import SgvIcon from 'src/assets/svg';
import { ROUTE_CONFIG } from 'src/routers/helpers';

const LayoutHeader = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const menuItems: ItemType[] = [
    {
      key: 'dashboard',
      icon: (
        <div className="div-sub-menu-icon">
          <SgvIcon.IcMenuDashboard />
          <span className="menu-title">{t('menuHeader.dashboard')}</span>
        </div>
      ),
      children: [
        {
          key: ROUTE_CONFIG.DASHBOARD_ORDER,
          label: (
            <Link to={ROUTE_CONFIG.DASHBOARD_ORDER}>
              <span>{t('menuHeader.mDashboardChild.order')}</span>
            </Link>
          ),
        },
        {
          key: ROUTE_CONFIG.DASHBOARD_SALARY,
          label: (
            <Link to={ROUTE_CONFIG.DASHBOARD_SALARY}>
              <span>{t('menuHeader.mDashboardChild.salary')}</span>
            </Link>
          ),
        },
      ],
    },
    {
      key: 'mOrder',
      icon: (
        <div className="div-sub-menu-icon">
          <SgvIcon.IcMenuOrder />
          <span className="menu-title">{t('menuHeader.mOrder')}</span>
        </div>
      ),
      children: [
        {
          key: ROUTE_CONFIG.ORDER_LIST,
          label: (
            <Link to={ROUTE_CONFIG.ORDER_LIST}>
              <span>{t('menuHeader.mOrderChild.order')}</span>
            </Link>
          ),
        },
        {
          key: ROUTE_CONFIG.MATERIAL_LIST,
          label: (
            <Link to={ROUTE_CONFIG.MATERIAL_LIST}>
              <span>{t('menuHeader.mOrderChild.material')}</span>
            </Link>
          ),
        },
        {
          key: ROUTE_CONFIG.CUSTOMER_LIST,
          label: (
            <Link to={ROUTE_CONFIG.CUSTOMER_LIST}>
              <span>{t('menuHeader.mOrderChild.customer')}</span>
            </Link>
          ),
        },
      ],
    },
    {
      key: 'mCustomer',
      icon: (
        <div className="div-sub-menu-icon">
          <SgvIcon.IcMenuQLKH />
          <span className="menu-title">{t('menuHeader.mCustomer')}</span>
        </div>
      ),
      children: [
        {
          key: ROUTE_CONFIG.USER_LIST, // Todo
          label: (
            <Link to={ROUTE_CONFIG.USER_LIST}>
              <span>{t('menuHeader.mCustomerChild.list')}</span>
            </Link>
          ),
        },
      ],
    },
    {
      key: 'setting',
      icon: (
        <div className="div-sub-menu-icon">
          <SgvIcon.IcMenuSetting />
          <span className="menu-title">{t('menuHeader.setting')}</span>
        </div>
      ),
      children: [
        {
          key: ROUTE_CONFIG.BRAND_LIST,
          label: (
            <Link to={ROUTE_CONFIG.BRAND_LIST}>
              <span>{t('menuHeader.mSettingChild.branch')}</span>
            </Link>
          ),
        },
        {
          key: ROUTE_CONFIG.PROPERTIES_LIST,
          label: (
            <Link to={ROUTE_CONFIG.PROPERTIES_LIST}>
              <span>{t('menuHeader.mSettingChild.properties')}</span>
            </Link>
          ),
        },
        {
          key: ROUTE_CONFIG.SALARY_LIST,
          label: (
            <Link to={ROUTE_CONFIG.SALARY_LIST}>
              <span>{t('menuHeader.mSettingChild.salary')}</span>
            </Link>
          ),
        },
      ],
    },
  ];

  return (
    <Menu
      defaultOpenKeys={[pathname]}
      selectedKeys={[pathname]}
      mode="horizontal"
      className="menu-layout-header"
      items={menuItems}
    />
  );
};

const LayoutHeaderComponent = React.memo(LayoutHeader);

export default LayoutHeaderComponent;
