import React, { lazy, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LayoutElement from 'src/containers/components/layout';
import { useAppSelector } from 'src/store/configureStore';
import { ROUTE_CONFIG } from './helpers';
import PrivateRoute from './PrivateRoute';

const Page404Screen = lazy(() => import('src/screens/errors/Page404'));

const DashboardScreen = lazy(() => import('src/screens/dashboard'));
const LoginScreen = lazy(() => import('src/screens/auth/login'));
const UserListScreen = lazy(() => import('src/screens/user/list'));
const CustomerListScreen = lazy(() => import('src/screens/customer/list'));
const BranchListScreen = lazy(() => import('src/screens/branch/list'));
const OrderListScreen = lazy(() => import('src/screens/order/list/index'));
const OrderCreateScreen = lazy(() => import('src/screens/order/add'));
const OrderDetailScreen = lazy(() => import('src/screens/order/detail'));
const PropertyListScreen = lazy(() => import('src/screens/property/list'));
const ContractSalaryScreen = lazy(() => import('src/screens/contract-salary/list'));
const MaterialScreen = lazy(() => import('src/screens/material/list'));
const DashboardOrderScreen = lazy(() => import('src/screens/dashboard/order'));
const DashboardSalaryScreen = lazy(() => import('src/screens/dashboard/salary'));

const NavigationComponent = () => {
  const navigation = useNavigate();
  const { user } = useAppSelector((state) => state.auth);

  const { pathname } = useLocation();

  useEffect(() => {
    if (user && pathname === ROUTE_CONFIG.LOGIN) {
      navigation(ROUTE_CONFIG.DASHBOARD);
    }
  }, [user]);

  return (
    <Routes>
      <Route path="/" element={<LayoutElement />}>
        <Route
          index
          element={
            <PrivateRoute>
              <DashboardScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTE_CONFIG.USER_LIST}
          element={
            <PrivateRoute>
              <UserListScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTE_CONFIG.CUSTOMER_LIST}
          element={
            <PrivateRoute>
              <CustomerListScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTE_CONFIG.BRAND_LIST}
          element={
            <PrivateRoute>
              <BranchListScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTE_CONFIG.PROPERTIES_LIST}
          element={
            <PrivateRoute>
              <PropertyListScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTE_CONFIG.SALARY_LIST}
          element={
            <PrivateRoute>
              <ContractSalaryScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTE_CONFIG.ORDER_LIST}
          element={
            <PrivateRoute>
              <OrderListScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTE_CONFIG.ORDER_DETAIL}
          element={
            <PrivateRoute>
              <OrderDetailScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTE_CONFIG.ORDER_ADD}
          element={
            <PrivateRoute>
              <OrderCreateScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={`${ROUTE_CONFIG.ORDER_EDIT}:id`}
          element={
            <PrivateRoute>
              <OrderCreateScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTE_CONFIG.MATERIAL_LIST}
          element={
            <PrivateRoute>
              <MaterialScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTE_CONFIG.DASHBOARD_ORDER}
          element={
            <PrivateRoute>
              <DashboardOrderScreen />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTE_CONFIG.DASHBOARD_SALARY}
          element={
            <PrivateRoute>
              <DashboardSalaryScreen />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path={ROUTE_CONFIG.LOGIN} element={<LoginScreen />} />
      <Route path="*" element={<Page404Screen />} />
    </Routes>
  );
};

export default React.memo(NavigationComponent);
