import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import SpinnerLoader from 'src/containers/components/loader';
import './App.css';
import ErrorProvider from './containers/hooks/errorHandling';
import { LoadingProvider } from './containers/hooks/spinner';
import LocaleProvider from './containers/components/locale';
import NavigationComponent from './routers';
import { persistor, store } from './store/configureStore';

const App = () => {
  return (
    <Provider store={store}>
      <LocaleProvider>
        <PersistGate loading={<SpinnerLoader />} persistor={persistor}>
          <ErrorProvider>
            <LoadingProvider>
              <Suspense fallback={<SpinnerLoader />}>
                <NavigationComponent />
              </Suspense>
            </LoadingProvider>
          </ErrorProvider>
        </PersistGate>
      </LocaleProvider>
    </Provider>
  );
};

export default App;
